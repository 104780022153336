import React from "react";
import styled from "styled-components";
import { INonprofit } from "../data/interfaces";
import ComponentBase from "../component-base";
import { colors } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";
import { CardGrid } from "./card-grid";
import { ContentCard, IContentCardProps } from "./content-card";

const RegionsMap = styled.img`
  width: 100%;
  margin: 24px 0;
`;

interface INonprofitProfileProgramsProps {
  data: INonprofit;
}

export class NonprofitProfilePrograms extends ComponentBase<INonprofitProfileProgramsProps> {
  public render(): JSX.Element {
    return (
      <Page id="programs" style={{ backgroundColor: colors.gray.lightMid }}>
        <Grid applyTopPadding={true}>
          <Column span={[12, 12, 8]}>
            <SectionTitle>{this.str.nonprofitProfileProgramsSectionTitle}</SectionTitle>
          </Column>
        </Grid>
        <CardGrid>{this.generateProgramCards()}</CardGrid>
        <Grid applyBottomPadding={true}>
          <Column span={[12, 12, 8]}>
            <SectionTitle>{this.str.nonprofitProfileRegionsMapSectionTitle}</SectionTitle>
          </Column>
          <Column span={[12]}>
            <RegionsMap
              src={this.props.data.regionsMapUrl}
              alt={`${this.str.nonprofitProfileRegionsMapAlt} ${this.props.data.name}`}
            />
          </Column>
        </Grid>
      </Page>
    );
  }

  private generateProgramCards = (): JSX.Element[] => {
    return this.props.data.programs.map((program: IContentCardProps, index: number) => {
      return (
        <ContentCard
          key={index}
          title={program.title}
          description={program.description}
          imageUrl={program.imageUrl}
          imageAltText={program.imageAltText}
          textAreaHeight="auto"
        />
      );
    });
  };
}
