import { ILocStrings } from "./interface";

const strings: ILocStrings = {
  lang: "en",

  //#region General

  unknownError: "Unknown error occured",
  commonPrivacyPolicy: "Privacy Policy",
  commonTermsAndConditions: "Terms and Conditions",

  //#endregion General

  //#region Nav

  navNonprofits: "Explore nonprofits",
  navWho: "Who we are",
  navContact: "Contact us",
  navDonate: "Donate",
  navLangSwitch: "Ganti ke Bahasa Indonesia",

  //#endregion Nav

  //#region /index

  homeHeroHeading: "Providing you with trusted information and access you need to do good in Indonesia",
  homeHeroImgAlt: "Children sitting on the floor and laughing",

  aboutWhoHeading: "Who we are",
  aboutWhoContent1:
    "We are an independent 501(c)(3) nonprofit organization connecting United States donors with trusted charities in Indonesia. Our actions are guided by our belief that every nonprofit in Indonesia should have an equal opportunity to gain access to United States donors. We work closely with Indonesian nonprofits to help them get more visibility and share their progress, so donors can see the impact of their contributions to the community and give with confidence.",
  aboutWhoContent2: "We do the hard work so you can focus on supporting the causes you care about.",
  aboutVisionHeading: "Our vision",
  aboutVisionContent: "A transparent and effective Indonesian charity ecosystem.",
  aboutMissionHeading: "Our mission",
  aboutMissionContent:
    "To make a positive difference in Indonesia by building a trusted network of Indonesian nonprofit organizations and connecting them with donors from the United States.",

  projectsHeading: "What we do",
  projectsSubheading: "These are the projects that we are working on.",
  projectsGivingTitle: "Giving platform",
  projectsGivingDescription: "Online giving platform for Indonesian charities.",
  projectsGivingImgAlt: "An Indonesian farmer working on a rice field",
  projectsCharityTitle: "Charity Assessment",
  projectsCharityDescription: "Directory and analysis of Indonesian nonprofit organizations.",
  projectsCharityImgAlt: "An instructor is teaching a group of Indonesian students in a classroom.",
  projectsCtaDonate: "Donate",
  projectsCtaExplore: "Explore",

  contactHeading: "Contact us",
  contactParagraph1:
    "Do you have any questions? Would you like to partner with us? Or if you just want to say hello, we would love to hear from you. Feel free to reach us at",
  contactParagraph2:
    "We are just getting started. Would you like us to keep you updated? Drop us your email in this form. We will not send you spams, only important updates on our progress. Promise.",

  subFirstNameText: "First name",
  subLastNameText: "Last name",
  subEmailText: "Email",
  subSubmitSubscribe: "Subscribe",
  subSubmitSubmitting: "Submitting...",
  subSubmitSuccess: "Submitted",
  subSubmitFailed: "Resubmit",
  subStatusSuccess: "Thanks!",
  subStatusFailed: "Failed to submit. Please try again.",

  footerProjects: "Projects",
  footerGivingPlatform: "Giving platform",
  footerCharityAssessment: "Charity assessment",
  footerContact: "Contact",
  footerInstagramAlt: "Give to Indonesia Instagram account",
  footerTwitterAlt: "Give to Indonesia Twitter account",
  footerFacebookAlt: "Give to Indonesia Facebook account",
  footerLinkedinAlt: "Give to Indonesia LinkedIn account",
  footerCopyright:
    "©2022 Give to Indonesia Foundation. All rights reserved. Give to Indonesia Foundation is a registered 501(c)(3) nonprofit organization. EIN: 85-1717201.",

  //#endregion /index

  //#region /donate

  donationHeroHeading: "Support our work",
  donationTitle: "Donate to Give to Indonesia",
  donationFormLoadingText: "Please wait",
  donationFormProcessingText: "Processing",
  donationFormFirstNameLabel: "First Name",
  donationFormFirstNameErrorMessage: "Please enter a valid first name.",
  donationFormLastNameLabel: "Last Name",
  donationFormLastNameErrorMessage: "Please enter a valid last name.",
  donationFormCompanyLabel: "Company",
  donationFormCompanyErrorMessage: "Please enter a valid company name.",
  donationFormEmailLabel: "Email",
  donationFormEmailErrorMessage: "Please enter a valid email address.",
  donationFormAnonymousLabel: "I would like to keep my donation anonymous.",
  donationFormCauseRequestLabel: "I would like to support a specific cause.",
  donationFormAmountLabel: "Amount",
  donationFormOtherAmountLabel: "Other",
  donationFormOtherAmountErrorMessage: "Please enter an amount in whole number greater than zero.",
  donationFormDonateButton: "Donate",
  donationFormSecurityMessage: "Your donation is safe and secure.",
  donationFormRequiredFieldDescription: "Required field.",
  donationFormLegalNotes:
    "Give to Indonesia Foundation will do its best to use your contributions for the purpose, if any, that you specified. This doesn't happen often, but if Give to Indonesia Foundation cannot fund the cause/charity you recommend, it will use your contributions for similar charitable purposes and, whenever possible, will consult with you on the reassignment.",
  donationFormInvalidInputFieldsErrorMessage: "Please correct the invalid field(s).",
  donationFormPaymentFailedErrorMessage: "Failed to process payment.",
  donationFormInternalServerError: "Internal server error has occured.",

  //#endregion /donate

  //#region /donation/{transactionId}

  donationReceiptHeading: "Thank you for supporting Give to Indonesia",
  donationReceiptTitleText: "Your donation of",
  donationReceiptFailedStatus: "is failed",
  donationReceiptCompletedStatus: "is completed",
  donationReceiptSubmittedStatus: "is submitted",
  donationReceiptMessageThankYou: "Thank you,",
  donationReceiptMessageAmount1: ", for your kind donation of",
  donationReceiptMessageAmount2: "to Give to Indonesia Foundation",
  donationReceiptMessageTransactionId: "Your transaction ID is",
  donationReceiptMessageEmail: "A receipt has been sent to",
  donationReceiptMessageKeepForRecords: "Please keep it for your record.",
  donationReceiptNotFoundErrorMessage: "Donation not found",
  donationReceiptPrintButton: "Print",
  donationReceiptPrintedVersionTitle: "Your donation to Give to Indonesia Foundation",
  donationReceiptPrintedVersionTransactionIdLabel: "Transaction ID",
  donationReceiptPrintedVersionDonorInformationHeading: "Donor Information",
  donationReceiptPrintedVersionNameLabel: "Name",
  donationReceiptPrintedVersionCompanyLabel: "Company",
  donationReceiptPrintedVersionTransactionInformationHeading: "Transaction Information",
  donationReceiptPrintedVersionAmountLabel: "Amount",
  donationReceiptPrintedVersionTransactionDateLabel: "Transaction Date",
  donationReceiptPrintedVersionPaymentInformationHeading: "Payment Information",
  donationReceiptPrintedVersionCreditCardTypeLabel: "Card Type",
  donationReceiptPrintedVersionCreditCardNumberLabel: "Credit Card Number",
  donationReceiptPrintedVersionAdditionalInfo:
    "in value described as monetary payment made online. The full amount of your donation is tax-deductible to the extent allowable by law since Give to Indonesia Foundation has not provided any goods or services to you in exchange for your contribution. This acknowledgement is provided pursuant to Section 170(f)(8) of the Internal Revenue code. Give to Indonesia Foundation is a registered 501(c)(3) nonprofit organization. EIN: 85-1717201. Please keep this document for your tax record.",
  donationReceiptPrintedVersionPaymentTypeLabel: "Payment Type",
  donationReceiptPrintedVersionVenmoIDLabel: "Venmo ID",

  //#endregion /donation/{transactionId}

  //#region /nonprofits

  nonprofitsHeroHeading: "Find and support charities working on causes that matter to you",
  nonprofitsHeroImgAlt: "An instructor teaching a group of Indonesian students in a classroom.",
  nonprofitsHeading: "Trusted Indonesian nonprofits",
  nonprofitsDescription:
    "These Indonesian charities are pre-vetted by Give to Indonesia. View their profile, read their stories, and support them in making a positive impact in local communities across Indonesia.",
  nonprofitsTileDoctorshareTitle: "doctorSHARE",
  nonprofitsTileDoctorshareDescription: "Bringing healthcare to the most inaccessible islands in Indonesia.",
  nonprofitsTileDoctorshareImgAlt:
    "One of doctorSHARE's floating hospital being docked in Tanimbar Islands, Indonesia.",
  nonprofitsTileDoctorshareTag: "Healthcare, Humanitarian aid",
  nonprofitsTileYadasTitle: "Yayasan Dairi Saroha",
  nonprofitsTileYadasDescription: "Empowering communities in the Dairi Regency.",
  nonprofitsTileYadasImgAlt:
    "Students from the Dairi Regency.",
  nonprofitsTileYadasTag: "Education, Livelihood, Civil society",

  //#endregion /nonprofits

  //#region NonprofitProfile

  nonprofitProfileInPageNavItemSummary: "Summary",
  nonprofitProfileInPageNavItemImpact: "Impact",
  nonprofitProfileInPageNavItemPrograms: "Programs",
  nonprofitProfileInPageNavItemFinancials: "Financials",
  nonprofitProfileSummaryReadMoreLink: "Learn more about what we do",
  nonprofitProfileVisionSectionTitle: "Vision",
  nonprofitProfileMissionSectionTitle: "Mission",
  nonprofitProfileInfoAka: "Other names",
  nonprofitProfileInfoWebsite: "Website",
  nonprofitProfileInfoAddress: "Address",
  nonprofitProfileInfoFounded: "Founded",
  nonprofitProfileInfoIssueAreas: "Subject areas",
  nonprofitProfileProgramsSectionTitle: "Programs",
  nonprofitProfileRegionsMapSectionTitle: "Regions served",
  nonprofitProfileRegionsMapAlt: "A map of Indonesian regions served by",
  nonprofitProfileImpactTitle: "Impact",
  nonprofitProfileImpactDescription: "Measures of progress and results information reported by",
  nonprofitProfileImpactMeasureDescription: "Totals by year",
  nonprofitProfileFinancialsTitle: "Financials",
  nonprofitProfileFinancialsDescription: "Financial data reported by",
  nonprofitProfileFinancialsCurrencyNote: "in Indonesian Rupiah",
  nonprofitProfileFinancialsFiscalYear: "Fiscal year",
  nonprofitProfileFinancialsRevenueExpenses: "Revenue and Expenses",
  nonprofitProfileFinancialsBalanceSheet: "Balance Sheet",
  nonprofitProfileFinancialsRevenue: "Revenue",
  nonprofitProfileFinancialsExpenses: "Expenses",
  nonprofitProfileFinancialsAssets: "Assets",
  nonprofitProfileFinancialsTotalRevenue: "Total revenue",
  nonprofitProfileFinancialsTotalExpenses: "Total expenses",
  nonprofitProfileFinancialsTotalAssets: "Total assets",
  nonprofitProfileFinancialsTotalLiabilities: "Total liabilities",
  nonprofitProfileFinancialsNetAssets: "Net assets",
  nonprofitProfileDonateTitlePart1: "Support",
  nonprofitProfileDonateTitlePart2: "through Give to Indonesia.",
  nonprofitProfileDonate: "Donate now",

  //#endregion NonprofitProfile

  //#region Nonprofit issue areas

  issueAreaHealthcare: "Healthcare",
  issuAreaHumanitarianAid: "Humanitarian aid",
  issueAreaEducation: "Education",
  issueAreaLivelihood: "Livelihood",
  issueAreaCivilSociety: "Civil society",

  //#endregion Nonprofit issue areas

  //#region /nonprofits/doctorshare

  doctorshareHeroImgAlt: "One of doctorSHARE's floating hospital being docked in Tanimbar Islands, Indonesia.",
  doctorshareSummaryTitle: "Bringing healthcare to the most inaccessible islands in Indonesia",
  doctorshareSummary:
    "In one fateful evening in 2009, dr. Lie Dharmawan, the founder of doctorSHARE, was in the process of finishing his last surgery during a medical service trip to Kei island in Maluku. As they were preparing to leave, a dying 9-year-old Susanti was suddenly rushed in by her distraught mother and begging the doctor to save her daughter’s life. Dr Lie discovered that she had taken her daughter sailing across the ocean for 3 days to get help because there was no doctors or help in the region she’s from.<br /><br />Susanti, laying unconscious in her arms, had a severe strangulated intestine and was at risk of internal bleeding. Despreate and frantic, Susanti’s mother had nowhere else to go for help.<br /><br />This is the issue that everyday Indonesians would have to face day to day when it comes to healthcare treatments. doctorSHARE aims to bridge the gap and provide healthcare to those without access and in needs.<br /><br />Being the fourth most populous country in the world and the largest archipelago nation, Indonesia has its own unique challenges when it comes to healthcare access. Many of Indonesia’s 17,000 islands are extremely remote, posing significant challenges in providing healthcare access throughout the nation. Financial hardships also often exacerbate this issue. Approximately 40% of Indonesia’s 269 million citizens are earning less than USD$ 3.10 per day. As a result, more than 60% of Indonesians do not have access to primary healthcare facilities.<br /><br />doctorSHARE brings accessible healthcare to the remote areas of Indonesia through its floating hospitals and flying doctors programs. Our goal is to save lives and alleviate suffering for those trapped in crisis, so that they can focus on rebuilding their future.",
  doctorShareVision: "Empowering people to come out of the suffering holistically by their own power.",
  doctorShareMission:
    "Improving Indonesia's health outcomes, particularly in the eastern parts, through holistic healthcare services and innovative sustainable programs with the spirit of volunteerism.",
  doctorShareLogoAlt: "doctorSHARE logo.",
  doctorShareProgram1CardTitle: "East Nusa Tenggara Emergency Response",
  doctorShareProgram1CardDescription:
    "Being the first and smallest private floating hospital in the world, RSA dr. Lie Dharmawan is equipped with ECG, USG, laboratory, surgery room, resuscitation space, and a patient examination room, similar to a Type D landed-hospital.",
  doctorShareProgram1CardImgAlt: "RSA dr. Lie Dharmawan, one of doctorSHARE's floating hospital.",
  doctorShareProgram2CardTitle: "Floating Hospital #2",
  doctorShareProgram2CardDescription:
    "doctorSHARE’s second floating hospital, RSA Nusa Waluya I, is a joint collaboration between doctorSHARE and Yayasan Eka Dharma. Equipped with ECG, USG, laboratory, two surgery rooms, resuscitation space, consultation room, dental clinic and 10 in-patient beds, similar to a Type D landed-hospital.",
  doctorShareProgram2CardImgAlt: "RSA Nusa Waluya I, one of doctorSHARE's floating hospital.",
  doctorShareProgram3CardTitle: "Floating Hospital #3",
  doctorShareProgram3CardDescription:
    "RSA Nusa Waluya II is the world's 1st hospital barge. It features 61 rooms including operating theaters, ICU, in-patient beds, and other secondary healthcare facilities, including radiology, laboratory, dental & eye care, and pharmacy, similar to a Type C landed-hospital.",
  doctorShareProgram3CardImgAlt: "RSA Nusa Waluya II, one of doctorSHARE's floating hospital.",
  doctorShareProgram4CardTitle: "Flying Doctors",
  doctorShareProgram4CardDescription:
    "To address the needs of people living in remote mountainous regions accessible only by air or foot, doctorSHARE partners with several small aircrafts pilots to reach these communities.",
  doctorShareProgram4CardImgAlt:
    "A group of Indonesian people are waiting for a helicopter to arrive in an area surrounded by mountains.",
  doctorShareProgram5CardTitle: "Therapeutic Feeding Center",
  doctorShareProgram5CardDescription:
    "Responding to the acute malnutrition issue in Maluku, doctorSHARE initiated the pilot therapeutic feeding program in the island of Kei by reactivating the clinic in 2018. This program aims to restore the help severely malnourished children under five and women who suffer from chronic energy deficiency by provisioning nutritious food and medical treatments.",
  doctorShareProgram5CardImgAlt: "A man is being fed with medicines by a medical professional.",
  doctorShareProgram6CardTitle: "Tuberculosis Program",
  doctorShareProgram6CardDescription:
    "The pilot TB Program in Papua is aimed to reduce the prevalence of TB through awareness, early detections,, complete treatments of TB suspects, and community empowerment to become PMO (Medicine Companion). The clinic also provides regular medical services for the local community.",
  doctorShareProgram6CardImgAlt: "A toddler is being carried by its mother.",
  doctorShareImpactMeasure1Title: "Major surgeries",
  doctorShareImpactMeasure2Title: "Minor surgeries",
  doctorShareImpactMeasure3Title: "Births assisted",
  doctorShareImpactMeasure4Title: "Outpatient treatments and consultations",
  doctorShareImpactMeasure5Title: "USG and antenatal",
  doctorShareImpactMeasure6Title: "Dental treatments",
  doctorShareImpactMeasure7Title: "Emergency patients treated",
  doctorShareImpactMeasure8Title: "Laboratory patients",
  doctorShareImpactMeasure9Title: "Pharmaceutical prescriptions",
  doctorShareImpactMeasure10Title: "Health educations",
  doctorShareImpactMeasure11Title: "Volunteers",
  doctorShareFinancialsRevenueRow1Name: "Grants",
  doctorShareFinancialsRevenueRow2Name: "Other revenues",
  doctorShareFinancialsExpensesRow1Name: "Programs",
  doctorShareFinancialsExpensesRow2Name: "General and administration",
  doctorShareFinancialsExpensesRow3Name: "Ships",
  doctorShareFinancialsExpensesRow4Name: "Other expenses",

  //#endregion /nonprofits/doctorshare

  //#region /nonprofits/yayasandairisaroha

  yadasHeroImgAlt: "Students from the Dairi Regency.",
  yadasSummaryTitle: "Empowering communities in the Dairi Regency",
  yadasSummary:
    "Yayasan Dairi Saroha (Yadas) is a nonprofit foundation formed by expatriates from the Dairi Regency area, North Sumatra, Indonesia. This foundation aims to develop Dairi Regency by partnering with the Dairi Regency government. The programs and activities of Yadas are directed toward improving human and community resources through seminars, provision of agricultural experts, and improvements in education through scholarships and provision of supporting tools and facilities. Furthermore, Yadas aims to help build infrastructure such as schools, roads, bridges and other facilities in the Dairi Regency.",
  yadasVision: "To become a partner of the Dairi Regency government in creating a prosperous Dairi Regency community.",
  yadasMission:
    "To gather Dairi diaspora community members to care and cooperate with the Dairi Regency government in improving the welfare of the Dairi people.",
  yadasLogoAlt: "Yayasan Dairi Saroha logo.",
  yadasProgram1CardTitle: "College scholarships",
  yadasProgram1CardDescription:
    "Providing scholarships to outstanding students from the Dairi Regency who are economically disadvantaged to study at national universities",
  yadasProgram1CardImgAlt: "A college scholarship recipient and his family.",
  yadasProgram2CardTitle: "Seminars for high school students",
  yadasProgram2CardDescription:
    "Annual motivational seminars for Dairi high school students.",
  yadasProgram2CardImgAlt: "High school students in a classroom.",
  yadasProgram3CardTitle: "Sidikalang Arabica coffee product development",
  yadasProgram3CardDescription:
    "Helping to develop the Sidikalang coffee industry through the formation of Farmers Groups and the provision of seminars and counseling for local coffee farmers.",
  yadasProgram3CardImgAlt: "Members of a Farmers Group.",
  yadasProgram4CardTitle: "Donations to Dairi schools",
  yadasProgram4CardDescription:
    "Donations to schools in the Dairi Regency in the form of equipment and tutoring programs.",
  yadasProgram4CardImgAlt:
    "Teachers receiving book donations.",
  yadasProgram5CardTitle: "English courses for elementary and middle school students",
  yadasProgram5CardDescription:
    "Provide English language courses to elementary and middle school students in various villages in the Dairi Regency.",
  yadasProgram5CardImgAlt:
    "Children in an English language course.",
  yadasImpactMeasure1Title: "National universities scholarship recipients",
  yadasImpactMeasure2Title: "Seminars for high school students",
  yadasImpactMeasure3Title: "Seminars and counseling for coffee farmers",
  yadasImpactMeasure4Title: "Schools donated",
  yadasImpactMeasure5Title: "Tutoring programs at schools",
  yadasImpactMeasure6Title: "English courses for elementary and middle school students",
  yadasFinancialsRevenueRow1Name: "Grants",
  yadasFinancialsRevenueRow2Name: "Interest",
  yadasFinancialsExpensesRow1Name: "Scholarships",
  yadasFinancialsExpensesRow2Name: "Local government secretariat assistance",
  yadasFinancialsExpensesRow3Name: "English teachers honorarium",
  yadasFinancialsExpensesRow4Name: "Administration and general",

  //#endregion /nonprofits/yayasandairisaroha

  //#region /amazonsmile

  smileHero: "Support us when you shop on Amazon",
  smileHeroImgAlt: "Indonesian farmers cycling next to paddy fields in the morning",
  smileHeading: "How to shop at AmazonSmile",
  smileSubheading:
    "AmazonSmile provides a simple way for you to support us every time you shop at Amazon, at no extra cost. To start contributing to us using your Amazon account, please follow these  steps.",
  smileDesktopHeading: "Setting up AmazonSmile on your desktop",
  smileDesktopItem1:
    "Go to <a href='https://smile.amazon.com' target='_blank' rel='noreferrer noopener'>smile.amazon.com</a> and sign in using your Amazon.com credentials.",
  smileDesktopItem2: "When prompted for a charitable organization, search for 'Give to Indonesia Foundation'.",
  smileDesktopItem2ImgAlt: "Search for Give to Indonesia Foundation",
  smileDesktopItem3: "Select 'Give to Indonesia Foundation' in the search results.",
  smileDesktopItem3ImgAlt: "Select Give to Indonesia Foundation in the search result",
  smileDesktopItem4:
    "Whenever you want to shop at Amazon, make sure to go to <a href='https://smile.amazon.com' target='_blank' rel='noreferrer noopener'>smile.amazon.com</a> instead of <a href='https://www.amazon.com' target='_blank' rel='noreferrer noopener'>www.amazon.com</a>. If you are visiting the correct page, you should see the AmazonSmile logo in the top left corner, and 'Give to Indonesia Foundation' under the search bar.",
  smileDesktopItem4ImgAlt: "AmazonSmile logo and Give to Indonesia Foundation text",
  smileDesktopItem5: "Happy shopping and thank you for your support.",
  smileMobileHeading: "Setting up AmazonSmile on your mobile app",
  smileMobileItem1:
    "Open the Amazon Shopping app on your phone and sign in using your Amazon.com credentials. If you do not have the Amazon Shopping app on your phone, you can download it from <a href='https://play.google.com/store/apps/details?id=com.amazon.mShop.android.shopping' target='_blank' rel='noreferrer noopener'>Google Play Store</a> or <a href='https://apps.apple.com/us/app/amazon-shopping/id297606951' target='_blank' rel='noreferrer noopener'>Apple App Store</a>.",
  smileMobileItem2: "Open the left menu, go to Settings > AmazonSmile.",
  smileMobileItem2ImgAlt: "AmazonSmile Mobile app menu",
  smileMobileItem3:
    "In the charity search field, enter 'Give to Indonesia Foundation' and hit Search. Then select 'Give to Indonesia Foundation' from the search results.",
  smileMobileItem3ImgAlt: "Mobile app search screen",
  smileMobileItem4:
    "If everything is set up correctly, there should be a screen that confirms that you are now contibuting to 'Give to Indonesia Foundation'. Please also make sure the AmazonSmile feature is turned on.",
  smileMobileItem4ImgAlt: "AmazonSmile mobile confirmation screen",
  smileMobileItem5:
    "You can now shop as usual using the app and Amazon will match a portion of your spending to contribute to 'Give to Indonesia Foundation'. Happy shopping and thank you for your support.",

  //#endregion /amazonsmile

  //#region /privacy

  privacyHero: "Privacy Policy",
  privacyHeroImgAlt: "Indonesian farmers cycling next to paddy fields in the morning",
  privacyIntroHeading: "Protecting your private information is our priority",
  privacyIntro: "This Statement of Privacy applies to https://givetoindonesia.org and Give to Indonesia Foundation, and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Give to Indonesia Foundation include https://givetoindonesia.org and GTIF. The GTIF website is a nonprofit donation and information portal site. By using the GTIF website, you consent to the data practices described in this statement.",
  privacySection1Heading: "Collection of your personal information",
  privacySection1Paragraph1: "In order to better provide you with products and services offered, GTIF may collect personally identifiable information, such as your:",
  privacySection1List1Item1: "First and Last Name",
  privacySection1List1Item2: "E-mail Address",
  privacySection1List1Item3: "Employer",
  privacySection1Paragraph2: "If you purchase GTIF's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.",
  privacySection1Paragraph3: "We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.",
  privacySection2Heading: "Use of your personal information",
  privacySection2Paragraph1: "GTIF collects and uses your personal information to operate and deliver the services you have requested. Notwithstanding the foregoing, you consent to GTIF sharing your name and the amount of your donation with any given GTIF third party non-profit recipient to whom you have designated such donation.",
  privacySection2Paragraph2: "GTIF may also use your personally identifiable information to inform you of other products or services available from GTIF and its affiliates.",
  privacySection3Heading: "Sharing information with third parties",
  privacySection3Paragraph1: "GTIF does not sell, rent or lease its customer lists to third parties.",
  privacySection3Paragraph2: "GTIF may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to GTIF, and they are required to maintain the confidentiality of your information.",
  privacySection3Paragraph3: "GTIF may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on GTIF or the site; (b) protect and defend the rights or property of GTIF; and/or (c) act under exigent circumstances to protect the personal safety of users of GTIF, or the public.",
  privacySection4Heading: "Automatically Collected Information",
  privacySection4Paragraph1: "Information about your computer hardware and software may be automatically collected by GTIF. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the GTIF website.",
  privacySection5Heading: "Use of cookies",
  privacySection5Paragraph1: "The GTIF website may use \"cookies to\" help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.",
  privacySection5Paragraph2: "One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize GTIF pages, or register with GTIF site or services, a cookie helps GTIF to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same GTIF website, the information you previously provided can be retrieved, so you can easily use the GTIF features that you customized.",
  privacySection5Paragraph3: "You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the GTIF services or websites you visit.",
  privacySection6Heading: "Links",
  privacySection6Paragraph1: "This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.",
  privacySection7Heading: "Security of your personal information",
  privacySection7Paragraph1: "GTIF secures your personal information from unauthorized access, use, or disclosure using the SSL Protocol.",
  privacySection7Paragraph2: "When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.",
  privacySection7Paragraph3: "We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.",
  privacySection8Heading: "Right to deletion",
  privacySection8Paragraph1: "Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:",
  privacySection8List1Item1: "Delete your personal information from our records; and",
  privacySection8List1Item2: "Direct any service providers to delete your personal information from their records.",
  privacySection8Paragraph2: "Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:",
  privacySection8List2Item1: "Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;",
  privacySection8List2Item2: "Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;",
  privacySection8List2Item3: "Debug to identify and repair errors that impair existing intended functionality;",
  privacySection8List2Item4: "Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;",
  privacySection8List2Item5: "Comply with the California Electronic Communications Privacy Act;",
  privacySection8List2Item6: "Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;",
  privacySection8List2Item7: "Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;",
  privacySection8List2Item8: "Comply with an existing legal obligation; or",
  privacySection8List2Item9: "Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.",
  privacySection9Heading: "Children under thirteen",
  privacySection9Paragraph1: "GTIF does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.",
  privacySection10Heading: "E-mail communications",
  privacySection10Paragraph1: "From time to time, GTIF may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.",
  privacySection10Paragraph2: "If you would like to stop receiving marketing or promotional communications via email from GTIF, you may opt out of such communications by emailing info@givetoindonesia.org.",
  privacySection11Heading: "External data storage sites",
  privacySection11Paragraph1: "We may store your data on servers provided by third party hosting vendors with whom we have contracted.",
  privacySection12Heading: "Changes to this statement",
  privacySection12Paragraph1: "GTIF reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.",
  privacySection13Heading: "Contact information",
  privacySection13Paragraph1: "GTIF welcomes your questions or comments regarding this Statement of Privacy. If you believe that GTIF has not adhered to this Statement, please contact GTIF at:",
  privacySection13Paragraph2: "Give to Indonesia Foundation\r\n15127 NE 24th St. Ste 766\r\nRedmond, Washington 98052",
  privacySection13Paragraph3: "Email Address:\r\ninfo@givetoindonesia.org",
  privacySection13Paragraph4: "Telephone number:\r\n+1 989 800 1945",
  privacySection13Paragraph5: "Effective as of June 01, 2021",

  //#endregion /privacy

  //#region /terms

  termsHero: "Terms and Conditions",
  termsHeroImgAlt: "Indonesian farmers cycling next to paddy fields in the morning",
  termsSection1Heading: "Agreement between User and https://givetoindonesia.org",
  termsSection1Paragraph1: "Welcome to https://givetoindonesia.org. The https://givetoindonesia.org website (the \"Site\") is comprised of various web pages operated by Give to Indonesia Foundation (\"GTIF\"). https://givetoindonesia.org is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the \"Terms\"). Your use of https://givetoindonesia.org constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.",
  termsSection1Paragraph2: "https://givetoindonesia.org is a Non-Profit Site. ",
  termsSection1Paragraph3: "This website is intended for United States donors to make donation to Give to Indonesia Foundation and learn about Indonesian nonprofits that partnered with Give to Indonesia Foundation. ",
  termsSection1Paragraph4: "Your donation will be made to Indonesia Foundation, a 501(c)(3) charity. Give to Indonesia Foundation covers all fees. Give to Indonesia Foundation will do its best to use your contributions for the purpose, if any, that you specified. This doesn't happen often, but if Give to Indonesia Foundation cannot fund the cause / charity you recommend, it will use your contributions for similar charitable purposes and whenever possible will consult with you on the reassignment. GTIF performs due diligence on the third party non-profit recipients of your donations. However, GTIF disclaims liability for any fraudulent activity or information provided by any such third party non-profit recipient of your donations.",
  termsSection2Heading: "Privacy",
  termsSection2Paragraph1: "Your use of https://givetoindonesia.org is subject to GTIF's",
  termsSection2Paragraph1b: ", which also governs the Site and informs users of our data collection practices.",
  termsSection3Heading: "Electronic communications",
  termsSection3Paragraph1: "Visiting https://givetoindonesia.org or sending emails to GTIF constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.",
  termsSection4Heading: "Children under thirteen",
  termsSection4Paragraph1: "GTIF does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use https://givetoindonesia.org only with permission of a parent or guardian.",
  termsSection5Heading: "Cancellation/refund policy",
  termsSection5Paragraph1: "Please email Give to Indonesia Foundation at info@givetoindonesia.org within 48 hours after the donation was made if you would like to get a refund. After the 48 hours window have passed, we would not be able to refund your donation. Please note that GTIF would not be able to refund the processing fees that are charged by the 3rd party payment processing company.",
  termsSection6Heading: "Links to third party sites/third party services",
  termsSection6Paragraph1: "https://givetoindonesia.org may contain links to other websites (\"Linked Sites\"). The Linked Sites are not under the control of GTIF and GTIF is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. GTIF is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by GTIF of the site or any association with its operators.",
  termsSection6Paragraph2: "Certain services made available via https://givetoindonesia.org are delivered by third party sites and organizations. By using any product, service or functionality originating from the https://givetoindonesia.org domain, you hereby acknowledge and consent that GTIF may share such information and data with any third party with whom GTIF has a contractual relationship to provide the requested product, service or functionality on behalf of https://givetoindonesia.org users and customers.",
  termsSection7Heading: "No unlawful or prohibited use/intellectual property",
  termsSection7Paragraph1: "You are granted a non-exclusive, non-transferable, revocable license to access and use https://givetoindonesia.org strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to GTIF that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.",
  termsSection7Paragraph2: "All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of GTIF or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.",
  termsSection7Paragraph3: "You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. GTIF content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of GTIF and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of GTIF or our licensors except as expressly authorized by these Terms.",
  termsSection8Heading: "International users",
  termsSection8Paragraph1: "The Service is controlled, operated and administered by GTIF from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the GTIF Content accessed through https://givetoindonesia.org in any country or in any manner prohibited by any applicable laws, restrictions or regulations.",
  termsSection9Heading: "Indemnification",
  termsSection9Paragraph1: "You agree to indemnify, defend and hold harmless GTIF, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. GTIF reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with GTIF in asserting any available defenses.",
  termsSection10Heading: "Arbitration",
  termsSection10Paragraph1: "In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.",
  termsSection11Heading: "Class action waiver",
  termsSection11Paragraph1: "Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and GTIF agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.",
  termsSection12Heading: "Liability disclaimer",
  termsSection12Paragraph1: "THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.",
  termsSection12Paragraph2: "GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED \"AS IS\" WITHOUT WARRANTY OR CONDITION OF ANY KIND. GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.",
  termsSection12Paragraph3: "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL GIVE TO INDONESIA FOUNDATION AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF GIVE TO INDONESIA FOUNDATION OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.",
  termsSection13Heading: "Termination/access restriction",
  termsSection13Paragraph1: "GTIF reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Washington and you hereby consent to the exclusive jurisdiction and venue of courts in Washington in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.",
  termsSection13Paragraph2: "You agree that no joint venture, partnership, employment, or agency relationship exists between you and GTIF as a result of this agreement or use of the Site. GTIF's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of GTIF's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by GTIF with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.",
  termsSection13Paragraph3: "Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and GTIF with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and GTIF with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.",
  termsSection14Heading: "Changes to terms",
  termsSection14Paragraph1: "GTIF reserves the right, in its sole discretion, to change the Terms under which https://givetoindonesia.org is offered. The most current version of the Terms will supersede all previous versions. GTIF encourages you to periodically review the Terms to stay informed of our updates.",
  termsSection15Heading: "Contact us",
  termsSection15Paragraph1: "GTIF is doing our best to ensure the information shared with and stored on GTIF’s site is accurate but sometimes there are inaccuracies. If you see a data discrepancy, please notify us at the below referenced email address.",
  termsSection15Paragraph2: "GTIF welcomes your questions or comments regarding the Terms:",
  termsSection15Paragraph3: "Give to Indonesia Foundation\r\n15127 NE 24th St Ste 766\r\nRedmond, Washington 98052",
  termsSection15Paragraph4: "Email Address:\r\ninfo@givetoindonesia.org",
  termsSection15Paragraph5: "Telephone number:\r\n+1 989 800 1945",
  termsSection15Paragraph6: "Effective as of June 01, 2021",

  //#endregion /terms
};

export default strings;
