import moment from "moment";
import React from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";

import { colors } from "../assets/variables/style-variables";
import ComponentBase from "../component-base";
import constants from "../constants";
import heroImage720 from "../assets/images/heroes/hero-paddy-720.jpg";
import heroImage1080 from "../assets/images/heroes/hero-paddy-1080.jpg";
import heroImage1440 from "../assets/images/heroes/hero-paddy-1440.jpg";
import logger from "../services/logger";
import { Button } from "./button";
import { Hero } from "./hero";
import { Page, Grid, Column } from "./grid";
import { Progress } from "./progress";
import { SectionTitle } from "./section-title";
import { Transaction } from "braintree";

const Bold = styled.strong`
  font-weight: 500;
`;

interface IDonationReceiptState {
  errorMessage: string | null;
  isLoading: boolean;
  receipt: Transaction | null;
}

class DonationReceiptComponent extends ComponentBase<RouteComponentProps, IDonationReceiptState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      errorMessage: null,
      isLoading: true,
      receipt: null,
    };
  }
  public async componentDidMount(): Promise<void> {
    // TODO: consider only allowing users to see the receipt once
    try {
      const response: AxiosResponse = (
        await axios.get(`${this.baseFunctionsUrl}/HttpBraintreeTransaction/${(this.props.match.params as any).id}`)
      ).data;

      if (!response) {
        // This should not be reachable because if there is no response,
        // it will go to the catch block below.
        throw new Error("Internal server error has occured.");
      } else {
        this.setState({
          receipt: response.data.transaction,
          isLoading: false,
        });

        logger.log(constants.logging.DONATE, "success");
      }
    } catch (err) {
      const error = err as AxiosError;
      let errorMessage: string;
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = this.str.donationReceiptNotFoundErrorMessage;
        } else if (error.response.status === 500) {
          errorMessage = this.str.donationFormInternalServerError;
        } else {
          // This error message comes from the API, so it will be non-localized.
          errorMessage = error.response.data.error.message;
        }
      } else {
        errorMessage = this.str.unknownError;
      }

      this.setState({
        errorMessage,
        receipt: null,
        isLoading: false,
      });
      logger.logError(constants.logging.DONATE, error);
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.donationReceiptHeading}
          backgroundPosition="center bottom"
          alt={this.str.smileHeroImgAlt}
          src={heroImage1080}
          srcVp0={heroImage720}
          srcVp5={heroImage1440}
        />
        <Page id="donation-receipt" backgroundColor={colors.gray.light}>
          <Grid applyPadding={true}>
            {this.state.isLoading ? (
              <Column span={[12]}>
                <Progress text={this.str.donationFormLoadingText} />
              </Column>
            ) : (
              this.renderReceipt()
            )}
          </Grid>
        </Page>
      </>
    );
  }

  private renderReceipt = (): JSX.Element => {
    if (!this.state.errorMessage && this.state.receipt) {
      return (
        <Column span={[12, 12, 8]}>
          <SectionTitle>
            {this.str.donationReceiptTitleText +
              " $" +
              this.state.receipt.amount +
              " " +
              this.generateStatusMessage(this.state.receipt.status)}
            .
          </SectionTitle>
          <p>
            {this.str.donationReceiptMessageThankYou} <Bold>{this.state.receipt.customer.firstName}</Bold>
            {this.str.donationReceiptMessageAmount1} <Bold>${this.state.receipt.amount}</Bold>{" "}
            {this.str.donationReceiptMessageAmount2}. {this.str.donationReceiptMessageTransactionId}{" "}
            <Bold>{this.state.receipt.id}</Bold>. {this.str.donationReceiptMessageEmail}{" "}
            <Bold>{this.state.receipt.customer.email}</Bold>. {this.str.donationReceiptMessageKeepForRecords}
          </p>

          <Button type="button" onClick={() => this.printIframe("receipt", "print-iframe")}>
            {this.str.donationReceiptPrintButton}
          </Button>

          <iframe id="print-iframe" title="Receipt" style={{ display: "none" }}></iframe>
          <div id="receipt" style={{ display: "none" }}>
            {this.generateReceipt()}
          </div>
        </Column>
      );
    } else {
      return (
        <Column span={[12]}>
          <SectionTitle>{this.state.errorMessage}</SectionTitle>
        </Column>
      );
    }
  };

  private generateStatusMessage = (status: string): string => {
    switch (status) {
      case "authorization_expired":
      case "settlement_declined":
      case "gateway_rejected":
      case "failed":
      case "processor_declined":
      case "voided":
        return this.str.donationReceiptFailedStatus;

      case "settled":
        return this.str.donationReceiptCompletedStatus;

      case "settling":
      case "submitted_for_settlement":
      case "authorizing":
      case "settlement_pending":
      case "authorized":
      default:
        return this.str.donationReceiptSubmittedStatus;
    }
  };

  private printIframe = (contentId: string, iframeId: string): void => {
    logger.log(constants.logging.DONATE, "print");

    const content = document.getElementById(contentId);
    const printWindow = (document.getElementById(iframeId) as HTMLIFrameElement).contentWindow;

    if (content !== null && printWindow !== null) {
      printWindow.document.open();
      printWindow.document.write(content.innerHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  };

  private getLocalTime = (time: string): string => {
    return moment(time).local().format("MM/DD/YYYY");
  };

  private generatePaymentInfoReceipt = (): JSX.Element => {
    const header = <h3>{this.str.donationReceiptPrintedVersionPaymentInformationHeading}</h3>;
    if (this.state.receipt!.venmoAccount) {
      return (
        <>
          {header}
          <p>
            {this.str.donationReceiptPrintedVersionPaymentTypeLabel}: {"Venmo"}{" "}
            {/* This is hardcoded because if this comes from the receipt, it will say "Venmo Account" */}
          </p>
          <p>
            {this.str.donationReceiptPrintedVersionVenmoIDLabel}: {this.state.receipt!.venmoAccount.venmoUserId}
          </p>
        </>
      );
    } else {
      return (
        <>
          {header}
          <p>
            {this.str.donationReceiptPrintedVersionCreditCardTypeLabel}: {this.state.receipt!.creditCard!.cardType}
          </p>
          {/* <p>
            {this.str.donationReceiptPrintedVersionCreditCardNumberLabel}: {this.state.receipt!.creditCard!.maskedNumber}
          </p> */}
        </>
      );
    }
  };

  private generateReceipt = (): JSX.Element => {
    return (
      <>
        <br />
        <h1>{this.str.donationReceiptPrintedVersionTitle}</h1>
        <h2>
          {this.str.donationReceiptPrintedVersionTransactionIdLabel}: {this.state.receipt!.id}
        </h2>
        <br />

        <h3>{this.str.donationReceiptPrintedVersionDonorInformationHeading}</h3>
        <p>
          {this.str.donationReceiptPrintedVersionNameLabel}:{" "}
          {this.state.receipt!.customer.firstName + " " + this.state.receipt!.customer.lastName}
        </p>
        <p>Email: {this.state.receipt!.customer.email}</p>
        <p>
          {this.str.donationReceiptPrintedVersionCompanyLabel}: {this.state.receipt!.customer.company}
        </p>
        <br />

        <h3>{this.str.donationReceiptPrintedVersionTransactionInformationHeading}</h3>
        <p>
          {this.str.donationReceiptPrintedVersionAmountLabel}:{" "}
          {this.state.receipt!.amount + " " + this.state.receipt!.currencyIsoCode}
        </p>
        <p>
          {this.str.donationReceiptPrintedVersionTransactionDateLabel} (MM/DD/YYYY):{" "}
          {this.getLocalTime(this.state.receipt!.createdAt.toString())}
        </p>
        <br />

        {this.generatePaymentInfoReceipt()}
        <br />

        <p>
          {this.str.donationReceiptMessageThankYou} <Bold>{this.state.receipt!.customer.firstName}</Bold>
          {this.str.donationReceiptMessageAmount1} <Bold>${this.state.receipt!.amount}</Bold>{" "}
          {this.str.donationReceiptMessageAmount2} {this.str.donationReceiptPrintedVersionAdditionalInfo}
        </p>
      </>
    );
  };
}

export const DonationReceipt = withRouter(DonationReceiptComponent);
