import React from "react";
import ComponentBase from "../../component-base";
import { colors } from "../../assets/variables/style-variables";
import { Page, Grid, Column } from "../grid";
import { Hero } from "../hero";
import { SectionTitle } from "../section-title";
import heroImage720 from "../../assets/images/heroes/hero-paddy-720.jpg";
import heroImage1080 from "../../assets/images/heroes/hero-paddy-1080.jpg";
import heroImage1440 from "../../assets/images/heroes/hero-paddy-1440.jpg";

export class Terms extends ComponentBase {
  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.termsHero}
          backgroundPosition="center bottom"
          alt={this.str.termsHeroImgAlt}
          src={heroImage1080}
          srcVp0={heroImage720}
          srcVp5={heroImage1440}
        />
        <Page backgroundColor={colors.gray.light}>
          <Grid applyPadding={true}>
            <Column span={[12, 12, 8]}>
              <SectionTitle>{this.str.termsSection1Heading}</SectionTitle>
              <p>{this.str.termsSection1Paragraph1}</p>
              <p>{this.str.termsSection1Paragraph2}</p>
              <p>{this.str.termsSection1Paragraph3}</p>
              <p>{this.str.termsSection1Paragraph4}</p>
              <SectionTitle>{this.str.termsSection2Heading}</SectionTitle>
              <p>
                {this.str.termsSection2Paragraph1} <a href="/privacy">{this.str.commonPrivacyPolicy}</a>
                {this.str.termsSection2Paragraph1b}.
              </p>
              <SectionTitle>{this.str.termsSection3Heading}</SectionTitle>
              <p>{this.str.termsSection3Paragraph1}</p>
              <SectionTitle>{this.str.termsSection4Heading}</SectionTitle>
              <p>{this.str.termsSection4Paragraph1}</p>
              <SectionTitle>{this.str.termsSection5Heading}</SectionTitle>
              <p>{this.str.termsSection5Paragraph1}</p>
              <SectionTitle>{this.str.termsSection6Heading}</SectionTitle>
              <p>{this.str.termsSection6Paragraph1}</p>
              <p>{this.str.termsSection6Paragraph2}</p>
              <SectionTitle>{this.str.termsSection7Heading}</SectionTitle>
              <p>{this.str.termsSection7Paragraph1}</p>
              <p>{this.str.termsSection7Paragraph2}</p>
              <p>{this.str.termsSection7Paragraph3}</p>
              <SectionTitle>{this.str.termsSection8Heading}</SectionTitle>
              <p>{this.str.termsSection8Paragraph1}</p>
              <SectionTitle>{this.str.termsSection9Heading}</SectionTitle>
              <p>{this.str.termsSection9Paragraph1}</p>
              <SectionTitle>{this.str.termsSection10Heading}</SectionTitle>
              <p>{this.str.termsSection10Paragraph1}</p>
              <SectionTitle>{this.str.termsSection11Heading}</SectionTitle>
              <p>{this.str.termsSection11Paragraph1}</p>
              <SectionTitle>{this.str.termsSection12Heading}</SectionTitle>
              <p>{this.str.termsSection12Paragraph1}</p>
              <p>{this.str.termsSection12Paragraph2}</p>
              <p>{this.str.termsSection12Paragraph3}</p>
              <SectionTitle>{this.str.termsSection13Heading}</SectionTitle>
              <p>{this.str.termsSection13Paragraph1}</p>
              <p>{this.str.termsSection13Paragraph2}</p>
              <p>{this.str.termsSection13Paragraph3}</p>
              <SectionTitle>{this.str.termsSection14Heading}</SectionTitle>
              <p>{this.str.termsSection14Paragraph1}</p>
              <SectionTitle>{this.str.termsSection15Heading}</SectionTitle>
              <p>{this.str.termsSection15Paragraph1}</p>
              <p>{this.str.termsSection15Paragraph2}</p>
              <p>{this.str.termsSection15Paragraph3}</p>
              <p>{this.str.termsSection15Paragraph4}</p>
              <p>{this.str.termsSection15Paragraph5}</p>
              <p>{this.str.termsSection15Paragraph6}</p>
            </Column>
          </Grid>
        </Page>
      </>
    );
  }
}
