import React from "react";
import styled from "styled-components";
import ComponentBase from "../component-base";
import { colors, fonts } from "../assets/variables/style-variables";
import { Card, ICardProps } from "./card-base";

interface IImpactDataPointBar {
  width: string;
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: ${colors.gray.dark};
  background-color: ${colors.gray.lightMid};
  font-size: 15px;
  line-height: 21px;

  h3 {
    margin-bottom: 12px;
    font-family: ${fonts.serif};
    font-size: 24px;
    line-height: 24px;
  }

  > span {
    flex: 1 1 100%;
    display: block;
    margin-bottom: 36px;
  }
`;

const ImpactDataPointBar = styled.div<IImpactDataPointBar>`
  display: flex;
  height: 24px;
  margin: 6px 0;
  align-items: center;

  div:first-of-type {
    flex: 0 0 48px;
  }

  div:last-of-type {
    display: flex;
    flex: 1 1 100%;
    height: 100%;
    padding: 0 8px;
    align-items: center;
    color: ${colors.white};
    background: ${(props) =>
      `linear-gradient(to right, ${colors.red.base} ${props.width}, transparent ${props.width})`};
  }
`;

const Total = styled.div`
  display: flex;
  margin-top: 36px;

  div:first-of-type {
    flex: 0 0 48px;
  }

  div:last-of-type {
    display: flex;
    flex: 1 1 100%;
    padding: 0 6px;
    font-weight: 600;
  }
`;

interface IImpactMeasureDataPoint {
  year: number;
  value: number;
}

export interface IImpactCardProps extends ICardProps {
  title: string;
  data: IImpactMeasureDataPoint[];
}

export class ImpactCard extends ComponentBase<IImpactCardProps, unknown> {
  private values: number[] = [];
  private highestValue: number;

  constructor(props: IImpactCardProps) {
    super(props);

    for (let i = 0; i < this.props.data.length; i++) {
      this.values.push(this.props.data[i].value);
    }

    this.highestValue = Math.max(...this.values);
  }

  public render(): JSX.Element {
    return (
      <StyledCard>
        <h3>{this.props.title}</h3>
        <span>{this.str.nonprofitProfileImpactMeasureDescription}</span>
        <div>{this.generateImpactDataPoints()}</div>
        <Total>
          <div>Total</div>
          <div>{this.getTotalValue()}</div>
        </Total>
      </StyledCard>
    );
  }

  private generateImpactDataPoints = (): JSX.Element[] => {
    return this.props.data.map((data: IImpactMeasureDataPoint, index: number) => {
      return (
        <ImpactDataPointBar key={index} width={this.generateBarWidthInPercentage(data.value)}>
          <div>{data.year}</div>
          <div>
            <span>{data.value}</span>
          </div>
        </ImpactDataPointBar>
      );
    });
  };

  private generateBarWidthInPercentage = (value: number): string => {
    return `${(value / this.highestValue) * 100}%`;
  };

  private getTotalValue = (): number => {
    return this.values.reduce((accumulator: number, currentValue: number) => accumulator + currentValue);
  };
}
