import React from "react";
import styled from "styled-components";
import { INonprofit } from "../data/interfaces";
import ComponentBase from "../component-base";
import { colors, breakpoints, fonts } from "../assets/variables/style-variables";
import { Page, Grid, Column } from "./grid";
import { SectionTitle } from "./section-title";

const StyledLink = styled.a`
  display: inline-block;
  margin: 12px 0 24px;
  color: ${colors.red.base};
  font-size: 18px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const SummaryTextContentContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 48px;

  @media (min-width: ${breakpoints.vp2}) {
    margin-bottom: 84px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoItem = styled.div`
  margin-bottom: 64px;

  img {
    max-width: 64%;
    margin-top: 48px;

    @media (min-width: ${breakpoints.vp3}) {
      max-width: 240px;
      margin-top: 0;
    }
  }

  h3 {
    position: relative;
    margin-bottom: 2px;
    font-family: ${fonts.serif};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 32px;

    &::before {
      content: "";
      position: absolute;
      width: 36px;
      height: 1px;
      top: -12px;
      left: 0;
      background-color: ${colors.red.light};
    }
  }

  div,
  ul > li {
    font-size: 15px;
    line-height: 24px;

    a {
      color: ${colors.red.base};

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  ul > li {
    margin: 0;
  }
`;

interface INonprofitProfileSummaryProps {
  data: INonprofit;
}

export class NonprofitProfileSummary extends ComponentBase<INonprofitProfileSummaryProps> {
  public render(): JSX.Element {
    return (
      <Page id="summary" backgroundColor={colors.gray.light}>
        <Grid applyPadding={true}>
          <Column span={[12, 12, 12, 8]}>
            <SummaryTextContentContainer>
              <SectionTitle>{this.props.data.summary.title}</SectionTitle>
              <p dangerouslySetInnerHTML={{ __html: this.props.data.summary.text }} />
              {this.props.data.website && (
                <StyledLink href={this.props.data.website.url} target="_blank" rel="noopener noreferrer">
                  {`${this.str.nonprofitProfileSummaryReadMoreLink} ›`}
                </StyledLink>
              )}
            </SummaryTextContentContainer>
            <SummaryTextContentContainer>
              <SectionTitle>{this.str.nonprofitProfileVisionSectionTitle}</SectionTitle>
              <p>{this.props.data.summary.vision}</p>
            </SummaryTextContentContainer>
            <SummaryTextContentContainer>
              <SectionTitle>{this.str.nonprofitProfileMissionSectionTitle}</SectionTitle>
              <p>{this.props.data.summary.mission}</p>
            </SummaryTextContentContainer>
          </Column>
          <Column span={[12, 12, 12, 3]} position={[1, 1, 1, 10]}>
            <InfoItem>
              <img src={this.props.data.logo} alt={this.str.doctorShareLogoAlt} />
            </InfoItem>
            <InfoItem>
              <h3>{this.str.nonprofitProfileInfoAka}</h3>
              <div>{this.props.data.aka}</div>
            </InfoItem>
            {this.props.data.website && (
              <InfoItem>
                <h3>{this.str.nonprofitProfileInfoWebsite}</h3>
                <div>
                  <a href={this.props.data.website.url} target="_blank" rel="noopener noreferrer">
                    {this.props.data.website.displayText}
                  </a>
                </div>
              </InfoItem>
            )}
            <InfoItem>
              <h3>{this.str.nonprofitProfileInfoAddress}</h3>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.address }} />
            </InfoItem>
            <InfoItem>
              <h3>{this.str.nonprofitProfileInfoFounded}</h3>
              <div>{this.props.data.founded}</div>
            </InfoItem>
            <InfoItem>
              <h3>{this.str.nonprofitProfileInfoIssueAreas}</h3>
              <ul>{this.generateIssueAreasItems()}</ul>
            </InfoItem>
          </Column>
        </Grid>
      </Page>
    );
  }

  private generateIssueAreasItems = (): JSX.Element[] => {
    return this.props.data.issueAreas.map((item: string, index: number) => {
      return <li key={index}>{item}</li>;
    });
  };
}
