import React from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { INonprofit } from "../data/interfaces";
import { getNonprofitDataById } from "../data/nonprofits-data";
import ComponentBase from "../component-base";
import { Page, Grid, Column } from "./grid";
import { Hero } from "./hero";
import { InPageNav } from "./in-page-nav";
import { SectionTitle } from "./section-title";
import { NonprofitProfileSummary } from "./nonprofit-profile-summary";
import { NonprofitProfilePrograms } from "./nonprofit-profile-programs";
import { NonprofitProfileImpact } from "./nonprofit-profile-impact";
import { NonprofitProfileFinancials } from "./nonprofit-profile-financials";
import { LinkButton } from "./link-button";

const DonateColumn = styled(Column)`
  text-align: center;
`;

class NonprofitProfileComponent extends ComponentBase<RouteComponentProps> {
  private data: INonprofit;

  constructor(props: any) {
    super(props);

    this.data = getNonprofitDataById((this.props.match.params as any).nonprofit, this.str);
  }

  public render(): JSX.Element {
    return (
      <>
        <Hero {...this.data.hero} />
        <InPageNav />
        <NonprofitProfileSummary data={this.data} />
        <NonprofitProfilePrograms data={this.data} />
        <NonprofitProfileImpact data={this.data} />
        <NonprofitProfileFinancials data={this.data} />
        <Page>
          <Grid applyPadding={true}>
            <DonateColumn span={[12]}>
              <SectionTitle
                isCenterAligned={true}
              >{`${this.str.nonprofitProfileDonateTitlePart1} ${this.data.name} ${this.str.nonprofitProfileDonateTitlePart2}`}</SectionTitle>
              <LinkButton to="/donate">{this.str.nonprofitProfileDonate}</LinkButton>
            </DonateColumn>
          </Grid>
        </Page>
      </>
    );
  }
}

export const NonprofitProfile = withRouter(NonprofitProfileComponent);
