import React from "react";
import styled from "styled-components";
import ComponentBase from "../../component-base";
import { colors } from "../../assets/variables/style-variables";
import { Page, Grid, Column } from "../grid";
import { CardGrid } from "../card-grid";
import { Hero } from "../hero";
import { SectionTitle } from "../section-title";
import { ContentCard } from "../content-card";
import heroImageDoctorshare720 from "../../assets/images/heroes/hero-classroom-720.jpg";
import heroImageDoctorshare1080 from "../../assets/images/heroes/hero-classroom-1080.jpg";
import heroImageDoctorshare1440 from "../../assets/images/heroes/hero-classroom-1440.jpg";
import profileTileDoctorshare from "../../assets/images/nonprofits/doctorshare/profile-tile.jpg";
import profileTileYadas from "../../assets/images/nonprofits/yadas/profile-tile.jpg";

const StyledPage = styled(Page)`
  background-color: ${colors.gray.lightMid};
`;

export class Nonprofits extends ComponentBase {
  public render(): JSX.Element {
    return (
      <>
        <Hero
          headingText={this.str.nonprofitsHeroHeading}
          backgroundPosition="center top"
          alt={this.str.nonprofitsHeroImgAlt}
          src={heroImageDoctorshare1080}
          srcVp0={heroImageDoctorshare720}
          srcVp5={heroImageDoctorshare1440}
        />
        <StyledPage>
          <Grid applyTopPadding={true}>
            <Column span={[12, 12, 8]}>
              <SectionTitle>{this.str.nonprofitsHeading}</SectionTitle>
              <p>{this.str.nonprofitsDescription}</p>
            </Column>
          </Grid>
          <CardGrid>
            <ContentCard
              title={this.str.nonprofitsTileDoctorshareTitle}
              description={this.str.nonprofitsTileDoctorshareDescription}
              imageUrl={profileTileDoctorshare}
              imageAltText={this.str.nonprofitsTileDoctorshareImgAlt}
              tag={this.str.nonprofitsTileDoctorshareTag}
              href="/nonprofits/doctorshare"
            />
            <ContentCard
              title={this.str.nonprofitsTileYadasTitle}
              description={this.str.nonprofitsTileYadasDescription}
              imageUrl={profileTileYadas}
              imageAltText={this.str.nonprofitsTileYadasImgAlt}
              tag={this.str.nonprofitsTileYadasTag}
              href="/nonprofits/yayasandairisaroha"
            />
          </CardGrid>
        </StyledPage>
      </>
    );
  }
}
